
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.columns {
  position: relative;
  z-index: 0;

  &:not(.dark):not(.hasImage) .column > *:first-child {
    margin-top: 0; // remove vertical gutters
  }

  &:not(.dark):not(.hasImage) .column > *:last-child {
    margin-bottom: 0; // remove vertical gutters
  }

  :global(.headlineblock) + & {
    margin-top: -$gutters-vertical + $space-sm;
  }

  + .columns {
    margin-top: 0;

    &.nonProductModule {
      margin-top: $space-lg;
    }
  }
}

.dark {
  background-color: $color-background-dark;
  color: $color-text-negative;

  a[href] {
    color: $color-text-negative;
  }
}

.inner {
  display: flex;
  flex-wrap: wrap;
  gap: $space-lg;
  margin-top: 0;
  margin-bottom: 0;
}

.column {
  flex: 0 0 100%;

  > * {
    padding: 0;
  }
}

@include media-breakpoint-up(lg) {
  .column {
    flex: 1 0 0;

    &.double {
      flex-grow: 2;
    }

    &.triple {
      flex-grow: 3;
    }
  }

  .inner {
    flex-wrap: nowrap;
  }
}
