
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.divider:before {
  content: '';
  display: block;
  margin-bottom: $space-sm;
  width: 70px;
  height: 5px;
  background-color: $color-background-dark;
}

.headline,
.subline {
  max-width: $max-width-blog;
  word-break: break-word;
}

.center {
  text-align: center;

  .headline,
  .subline {
    margin: 0 auto;
  }

  .divider:before {
    margin: 0 auto $space-sm;
  }
}

.right {
  text-align: right;

  .headline,
  .subline {
    max-width: initial;
  }
}
